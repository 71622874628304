import React from "react";

const BlockSimple = ({ data }) => (
  <div className="banner-bottom">
    <div className="container">
      <div className="mercado mercado2">
        <h2
          dangerouslySetInnerHTML={{
            __html: data.title,
          }}
        ></h2>
        <div className="mercadosubtitle">{data.subtitle}</div>

        <div className="list-items d-flex flex-wrap">
          {data.TitleText.map((val, index) => {
            return (
              <div
                className={
                  index % 2 === 0 ? "item-left col-md-6" : "item-right col-md-6"
                }
                key={index}
              >
                <h3
                  dangerouslySetInnerHTML={{
                    __html: val.title,
                  }}
                ></h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: val.description,
                  }}
                ></div>
              </div>
            );
          })}
          <div className="clear"></div>
        </div>
      </div>
    </div>
  </div>
);

export { BlockSimple };
