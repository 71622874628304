import React from "react";

import { Seo } from "../Layout/Seo";
import { Header } from "./Header";
import { Block2 } from "../SendMoneyPage/Block2";
import { Block3 } from "../SendMoneyPage/Block3";
import { StepByStep } from "../StepByStep";
import { Block5 } from "../SendMoneyPage/Block5/Block5";
import { CallToAction } from "../Home/CallToAction";
import { Block6 } from "./Block6";
import { Posts } from "../Posts";
import { BlockSimple } from "./BlockSimple";

const RechargesPage = ({ pageContext, location, lang }) => {
  const { Recharges } = pageContext;
  const fragments = Recharges;
  return (
    <Seo
      title={fragments.SeoTitle}
      description={fragments.SeoDescription}
      lang={lang}
    >
      <div className="cn_page_category" style={{ display: "none" }}>
        recargas
      </div>
      <Header data={fragments.HeaderServices} lang={lang} />
      <Block2 data={fragments.block2recharge} />
      <Block3 data={fragments.block3recharge} />
      <StepByStep data={fragments.block4recharge} type={true} />
      <Block5 data={fragments.block5recharge} type={true} />
      <CallToAction data={fragments.block6recharge} />
      <Block6
        data={fragments.block7recharge}
        datalink={fragments.Block7sub}
        islink="true"
      />
      <Posts location={location} lang={lang} />
      <BlockSimple data={fragments.BlockSimple} />
    </Seo>
  );
};

export default RechargesPage;
