import React, { Fragment } from "react";
import { MoneyRecharge } from "../../MoneyRecharge";
import wave from "../../../images/wave.svg";

const Header = ({ data, lang }) => {
  const buttonRenderCondition =
    !!data.link.length && typeof data.link === "string";
  const linkButton = buttonRenderCondition && (
    <a href={data.link} id={data.clickID}>
      <span>{data.textlink}</span>
    </a>
  );

  return (
    <Fragment>
      <div className="bannerrecharge">
        <div className="container">
          <MoneyRecharge lang={lang} />
        </div>
      </div>
      <div className="wave-container">
        <img loading="eager" src={wave} alt="Wave" className="wave" />
      </div>
    </Fragment>
  );
};

export { Header };
