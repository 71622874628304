import React from "react";

export const CallToAction = ({ data, isHome }) => {
  return (
    <div
      className="availability"
      style={{ backgroundColor: isHome ? "#36b9d0" : "#59518e" }}
    >
      <div className="container">
        <div className="avail-in d-flex flex-wrap">
          <div className="service-in col-lg-7 col-md-12">
            <h2
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            ></h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.text,
              }}
            ></div>
          </div>
          <div className="whatsapp col-lg-5 col-md-12">
            <a
              href={`${data.whatsapp.path}`}
              target={data.whatsapp.target}
              style={{ backgroundColor: isHome ? "#59518e" : "#36b9d0" }}
            >
              {/* <i className={data.whatsapp.classes} aria-hidden="true"></i>{" "} */}
              {data.whatsapp.name}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
